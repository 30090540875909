import React from 'react'
import './about.css'
import ME from '../../assets/sagar1.PNG'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

export const about = () => {
  return (
    <section id='about'>
      <h5>Want to know me better?</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className='about_me'>
          <div className="about_me-img">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className='about_content'>
          <div className="about_cards">

            <div className="about_card">
              <FaAward className='about_icon' />
              <h5>Experience</h5>
              <small>7+ Years Working</small>
            </div>

            <div className="about_card">
              <FiUsers className='about_icon' />
              <h5>Clients</h5>
              <small>150+ Worldwide</small>
            </div>

            <div className="about_card">
              <VscFolderLibrary className='about_icon' />
              <h5>Projects</h5>
              <small>250+ Completed</small>
            </div>

          </div>
          <p>
          I am an IT Project Leader by profession, experienced in excellently managing time, scope & cost for various types of Projects. I really do enjoy solving real world problems with leading technologies and innovation. I am a fun loving person so I keep my team and associates always on toe for new ways of working, learning & growing together. My clients have been my backbone throughout my career as my relationship has grown with them with my years of experience, I cherish each and everyone of them. My friends & family call me too ambitious, how can I help you today?
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>

    </section>
  )
}
export default about
