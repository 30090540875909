import React from 'react'
import './testimonials.css'
import AVATR1 from '../../assets/a1.jpeg'
import AVATR2 from '../../assets/a2.jpeg'
import AVATR3 from '../../assets/a3.jpeg'


// import Swiper core and required modules
import { Pagination, Navigation } from "swiper";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
SwiperCore.use([Autoplay]);


export const testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className='container testimonials_container'
            // install Swiper modules
            modules={[Pagination]}
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false
            }}
      >
        <SwiperSlide className='testimonial'>
          <div className='client_avatar'>
            <img src={AVATR1} alt="" />
          </div>
          <h5 className='client_name'>Karan Thereja</h5>
            <small className='client_review'>
            I had the pleasure of working with Sagar on a collaborative project in 2021.
            His attention to detail, customer centric outlook, strong interpersonal and project management skills are enviable and provides him an advantage over his peers.
            I unequivocally recommend him and am confident that he will be an advantage to the organization he is associated with.
            </small>
        </SwiperSlide>

        <SwiperSlide className='testimonial'>
          <div className='client_avatar'>
            <img src={AVATR2} alt="" />
          </div>
          <h5 className='client_name'>Kenneth Carter Eshé</h5>
            <small className='client_review'>
            I've worked with Sagar and his team to help develop an MVP website through design, development, and its ultimate launch. Sagar helped add value by providing feedback across the original designs and organizing his teams to meet the project's needs. And even though we were working across a 12-hour time difference through a pandemic, Sagar was always punctual, responsive, and resilient in helping get the website launched!
            </small>
        </SwiperSlide>

        <SwiperSlide className='testimonial'>
          <div className='client_avatar'>
            <img src={AVATR3} alt="" />
          </div>
          <h5 className='client_name'>Wade Smith</h5>
            <small className='client_review'>
            Sagar is a talented project manager who helped our organization launch a complex web tool in a very short time frame. We were successful in large part due to Sagar's structured project management style, problem solving and quick response times.
            </small>
        </SwiperSlide>

      </Swiper>
    </section>
  )
}
export default testimonials