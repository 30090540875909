import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'

import { useRef } from 'react';
import emailjs from 'emailjs-com'



export const contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9fevfpy', 'template_tmgkxiq', form.current, 'V-iPUNbTmuag4YNQc')
      e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className='container contact_container'>
        <div className='contact_options'>
        <article className='contact_option'>
          <MdOutlineEmail className='contact_option-icon'/>
          <h4>Email</h4>
          <h5><a href="mailto:Ersagarpathak@gmail.com" target="_blank">rsagarpathak@gmail.com</a></h5>
          <a href="mailto:Ersagarpathak@gmail.com" target="_blank">Send a message</a>
        </article>
        <article className='contact_option'>
          <BsWhatsapp className='contact_option-icon'/>
          <h4>WhatsApp</h4>
          <h5><a href="https://wa.me/971585396904" target="_blank">+971-585396904(Dubai)</a><br></br>
          <a href="https://wa.me/918860689698" target="_blank">+91-8860689698 (India)</a></h5>
          <a href="https://wa.me/971585396904" target="_blank">Send a message</a>
        </article>
        </div>
        {/* End contatct options */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" placeholder='Your Message' rows="7"></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}
export default contact