import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/p1.png'
import IMG2 from '../../assets/p2.png'
import IMG3 from '../../assets/p3.png'
import IMG4 from '../../assets/portfolio4.png'
import IMG5 from '../../assets/p5.png'
import IMG6 from '../../assets/p6.png'
import IMG7 from '../../assets/app1.jpg'
import IMG8 from '../../assets/app2.png'


export const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>My Portfolio of Projects Delivered with Pride</h2>
      <div className='container portfolio_container'>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG1} alt="" />
          </div>
          <h3>UshookaSupply</h3>
          <a href="http://www.ushookahsupply.com" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG2} alt="" />
          </div>
          <h3>Healthy Home</h3>
          <a href="www.healthyhome.co.in" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG3} alt="" />
          </div>
          <h3>Freeminded</h3>
          <a href="http://www.freeminded.co" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG4} alt="" />
          </div>
          <h3>Redtag</h3>
          <a href="https://sa.redtag-stores.com/" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG5} alt="" />
          </div>
          <h3>Museum of the Future</h3>
          <a href="https://museumofthefuture.ae/" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG6} alt="" />
          </div>
          <h3>DocScheduler</h3>
          <a href="www.docscheduler.com" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>
        
      </div>

      <h2 className='m-app'>Mobile Apps</h2>
      <div className='container portfolio_container'>

        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG7} alt="" />
          </div>
          <h3>Rekhta (Android)</h3>
          <a href="https://play.google.com/store/search?q=rekhta&c=apps" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>

        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG8} alt="" />
          </div>
          <h3>Dr Lal Path Labs(iOS)</h3>
          <a href="https://apps.apple.com/in/app/health-connect-lpl/id1524740657" className='btn btn-primary' target="_blank">Live Demo</a>
        </article>

      </div>
    </section>
  )
}
export default portfolio
