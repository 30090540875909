import React from 'react'
import {AiFillLinkedin} from 'react-icons/ai'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {BsTwitter} from 'react-icons/bs'

export const HeaderSocials = () => {
  return (
    <div className='header_social '>
        <a href="https://www.linkedin.com/in/sagar-pathak-/" target="_blank"><AiFillLinkedin /></a>
        <a href="http://www.facebook.com/immortal.human"><FaFacebookF /></a>
        <a href="http://www.instagram.com/kabir.music"><FiInstagram /></a>
        <a href="https://twitter.com/erkabir"><BsTwitter /></a>
    </div>
  )
}
export default HeaderSocials
